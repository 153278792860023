import { get, put, del, post } from 'aws-amplify/api';
import { Client } from '../model/Client';
import { Goal } from '../model/Goal';
import { Budget } from '../model/Budget';
import { Employee } from '../model/Employee';
import { Authorization } from '../model/Authorization';
import { ExchangeRate } from '../model/ExchangeRate';
import { FinancialElement } from '../model/FinancialElement';
import { Valuation } from '../model/Valuation';
import { OperationDetail } from '../model/OperationDetail';
import { Operation } from '../model/Operation';

function runAndRetry<T>(fn: () => Promise<T>, options: { retries?: number, delay?: number, backoffFactor?: number } = {}): Promise<T> {
    const { retries = 2, delay = 500, backoffFactor = 2 } = options;

    const attempt = async (remainingRetries: number, currentDelay: number): Promise<T> => {
        try {
            return await fn();
        } catch (error) {
            if (remainingRetries === 0) {
                throw error;
            }
            await new Promise(resolve => setTimeout(resolve, currentDelay));
            return attempt(remainingRetries - 1, currentDelay * backoffFactor);
        }
    };

    return attempt(retries, delay);
}

const API = {
    get: async (apiName: string, path: string, options?: any): Promise<any> => {
        const response = await get({ apiName, path, options }).response;
        return await response.body.json();
    },
    put: async (apiName: string, path: string, options?: any): Promise<any> => {
        const response = await put({ apiName, path, options }).response;
        return await response.body.json();
    },
    post: async (apiName: string, path: string, options?: any): Promise<any> => {
        const response = await post({ apiName, path, options }).response;
        return await response.body.json();
    },
    del: async (apiName: string, path: string, options?: any): Promise<any> => {
        await del({ apiName, path, options }).response;
    }
};

const apiName = 'clients_api';

export function getEmployee(employeeId: string): Promise<Employee> {
    return API.get(apiName, `/employees/${employeeId}`, {});
};

export function listEmployees(): Promise<Employee[]> {
    return API.get(apiName, `/employees`, {});
};

export function listAuthorizations(): Promise<Authorization[]> {
    return API.get(apiName, `/authorizations`, {});
};

export function createAuthorization(newAuthorization: Authorization): Promise<Authorization> {
    return API.post(apiName, `/authorizations`, { body: newAuthorization });
};

export function deleteAuthorization(employeeId: string, clientId: string): Promise<void> {
    return API.del(apiName, `/authorizations/${employeeId}/${clientId}`, {});
};

export function listClients(all: boolean = false): Promise<Client[]> {
    return API.get(apiName, `/clients`, { queryParams: { all: all } });
};

export function getClient(clientId: string): Promise<Client> {
    return API.get(apiName, `/clients/${clientId}`, {});
};

export function updateClient(clientId: string, newClient: Client): Promise<Client> {
    return API.put(apiName, `/clients/${clientId}`, { body: newClient });
};

export async function listOperations(clientId: string, queryParams: any): Promise<Operation[]> {
    const operations: Operation[] = [];
    let operationsResponse = await runAndRetry(() => API.get(apiName, `/clients/${clientId}/operations`, { queryParams: queryParams }));
    operations.push(...operationsResponse['Items']);
    for (let i = 0; i < 10 && 'LastEvaluatedKey' in operationsResponse; i++) {
        const queryParamsWithLastEvaluatedKey = { ...queryParams };
        const LastEvaluatedKey = operationsResponse['LastEvaluatedKey'];
        if ('id' in LastEvaluatedKey) {
            queryParamsWithLastEvaluatedKey['LastEvaluatedKeyId'] = LastEvaluatedKey['id'];
        }
        if ('timestamp#id' in LastEvaluatedKey) {
            queryParamsWithLastEvaluatedKey['LastEvaluatedKeyTimestampId'] = LastEvaluatedKey['timestamp#id'];
        }
        await new Promise(resolve => setTimeout(resolve, 250));
        operationsResponse = await runAndRetry(() => API.get(apiName, `/clients/${clientId}/operations`, { queryParams: queryParamsWithLastEvaluatedKey }));
        operations.push(...operationsResponse['Items']);
    }

    return operations;
};

export function createOperation(clientId: string, newOperation: Operation): Promise<Operation> {
    return API.post(apiName, `/clients/${clientId}/operations`, { body: newOperation });
};

export function createOperationBatch(clientId: string, newOperations: Operation[]): Promise<Operation[]> {
    return API.post(apiName, `/clients/${clientId}/operations-batch`, { body: newOperations });
};

export function getOperation(clientId: string, id: string): Promise<Operation> {
    return runAndRetry(() => API.get(apiName, `/clients/${clientId}/operations/${id}`, {}));
};

export function updateOperation(clientId: string, id: string, newOperation: Operation): Promise<void> {
    return runAndRetry(() => API.put(apiName, `/clients/${clientId}/operations/${id}`, { body: newOperation }));
};

export function deleteOperation(clientId: string, id: string): Promise<void> {
    return runAndRetry(() => API.del(apiName, `/clients/${clientId}/operations/${id}`, {}));
};

export async function deleteOperationBatch(clientId: string, operationsIds: string[]): Promise<void> {
    await runAndRetry(() => put({ apiName: apiName, path: `/clients/${clientId}/operations-batch`, options: { body: operationsIds } }).response);  // This should be DELETE instead of PUT. It's just a temporary solution to this issue: https://github.com/aws-amplify/amplify-js/issues/12844
    // return runAndRetry(() => API.del(apiName, `/clients/${clientId}/operations-batch`, { body: operationsIds }));
};

export function listGoals(clientId: string): Promise<Goal[]> {
    return API.get(apiName, `/clients/${clientId}/goals`, {});
};

export function createGoal(clientId: string, newGoal: Goal): Promise<Goal> {
    return API.post(apiName, `/clients/${clientId}/goals`, { body: newGoal });
};

export function getGoal(clientId: string, goalId: string): Promise<Goal> {
    return API.get(apiName, `/clients/${clientId}/goals/${goalId}`, {});
};

export function updateGoal(clientId: string, goalId: string, newGoal: Goal): Promise<void> {
    return API.put(apiName, `/clients/${clientId}/goals/${goalId}`, { body: newGoal });
};

export function deleteGoal(clientId: string, goalId: string): Promise<void> {
    return API.del(apiName, `/clients/${clientId}/goals/${goalId}`, {});
};

export function listBudgets(clientId: string): Promise<Budget[]> {
    return API.get(apiName, `/clients/${clientId}/budgets`, {});
};

export function createBudget(clientId: string, newBudget: Budget): Promise<Budget> {
    return API.post(apiName, `/clients/${clientId}/budgets`, { body: newBudget });
};

export function getBudget(clientId: string, timestampTo: string): Promise<Budget> {
    return API.get(apiName, `/clients/${clientId}/budgets/${timestampTo}`, {});
};

export function updateBudget(clientId: string, timestampTo: string, newBudget: Budget): Promise<void> {
    return API.put(apiName, `/clients/${clientId}/budgets/${timestampTo}`, { body: newBudget });
};

export function deleteBudget(clientId: string, timestampTo: string): Promise<void> {
    return API.del(apiName, `/clients/${clientId}/budgets/${timestampTo}`, {});
};

export function listExchangeRates(codes: string[], queryParams: any): Promise<ExchangeRate[]> {
    return API.get(apiName, `/exchange_rates?codes=${codes.join('&codes=')}`, { queryParams: queryParams });
};

export function createExchangeRate(newExchangeRate: ExchangeRate): Promise<ExchangeRate> {
    return API.post(apiName, `/exchange_rates`, { body: newExchangeRate });
};

export function getExchangeRate(code: string, timestamp: string): Promise<ExchangeRate> {
    return API.get(apiName, `/exchange_rates/${code}/${timestamp}`, {});
};

export function updateExchangeRate(code: string, timestamp: string, newExchangeRate: ExchangeRate): Promise<void> {
    return API.put(apiName, `/exchange_rates/${code}/${timestamp}`, { body: newExchangeRate });
};

export function deleteExchangeRate(code: string, timestamp: string): Promise<void> {
    return API.del(apiName, `/exchange_rates/${code}/${timestamp}`, {});
};


export function listFinancialElements(clientId: string): Promise<FinancialElement[]> {
    return API.get(apiName, `/clients/${clientId}/financial_elements`, {});
};

export function createFinancialElement(clientId: string, newFinancialElement: FinancialElement): Promise<FinancialElement> {
    return API.post(apiName, `/clients/${clientId}/financial_elements`, { body: newFinancialElement });
};

export function getFinancialElement(clientId: string, id: string): Promise<FinancialElement> {
    return API.get(apiName, `/clients/${clientId}/financial_elements/${id}`, {});
};

export function updateFinancialElement(clientId: string, id: string, newFinancialElement: FinancialElement): Promise<void> {
    return API.put(apiName, `/clients/${clientId}/financial_elements/${id}`, { body: newFinancialElement });
};

export function deleteFinancialElement(clientId: string, id: string): Promise<void> {
    return API.del(apiName, `/clients/${clientId}/financial_elements/${id}`, {});
};

export function listValuations(clientId: string, queryParams?: any): Promise<Valuation[]> {
    return API.get(apiName, `/clients/${clientId}/valuations`, { queryParams: queryParams });
};

export function createValuation(clientId: string, newValuation: Valuation): Promise<Valuation> {
    return API.post(apiName, `/clients/${clientId}/valuations`, { body: newValuation });
};

export function getValuation(clientId: string, id: string): Promise<Valuation> {
    return API.get(apiName, `/clients/${clientId}/valuations/${id}`, {});
};

export function updateValuation(clientId: string, id: string, newValuation: Valuation): Promise<void> {
    return API.put(apiName, `/clients/${clientId}/valuations/${id}`, { body: newValuation });
};

export function deleteValuation(clientId: string, id: string): Promise<void> {
    return API.del(apiName, `/clients/${clientId}/valuations/${id}`, {});
};

export function listOperationDetails(clientId: string): Promise<OperationDetail[]> {
    return API.get(apiName, `/clients/${clientId}/operation_details`, {});
};

export function createOperationDetail(clientId: string, newOperationDetail: OperationDetail): Promise<OperationDetail> {
    return API.post(apiName, `/clients/${clientId}/operation_details`, { body: newOperationDetail });
};

export function getOperationDetail(clientId: string, id: string): Promise<OperationDetail> {
    return API.get(apiName, `/clients/${clientId}/operation_details/${id}`, {});
};

export function updateOperationDetail(clientId: string, id: string, newOperationDetail: OperationDetail): Promise<void> {
    return API.put(apiName, `/clients/${clientId}/operation_details/${id}`, { body: newOperationDetail });
};

export function deleteOperationDetail(clientId: string, id: string): Promise<void> {
    return API.del(apiName, `/clients/${clientId}/operation_details/${id}`, {});
};

export function listClientAppointments(clientId: string): Promise<{ timestamps: string[], duration_minutes: string }> {
    return API.get(apiName, `/clients/${clientId}/appointments`, {});
};

export function listAppointments(): Promise<{ timestamp: string, client_id: string }[]> {
    return API.get(apiName, `/appointments`, {});
};
